import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {fetchDataFromAPI} from '../../utils/api';
import Processing from '../../components/Processing/Processing';
import logo from '../../assets/svgs/logo.svg';
import {flushSync} from 'react-dom';
import {toast} from 'react-toastify';

const FormNewPass = () => {
  const navigate = useNavigate();

  const [showNewPass, setShowNewPass] = useState(false);
  const [showConNewPass, setShowConNewPass] = useState(false);

  const [searchParams] = useSearchParams();

  const userId = searchParams.get('userId');
  const email = searchParams.get('userEmail');
  const token = searchParams.get('userToken');

  const type = searchParams.get('type');

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      newPass: '',
      conNewPass: '',
    },
    validationSchema: Yup.object(
      type === '1'
        ? {
            newPass: Yup.string()
              .matches(
                /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[.@#$%^&+=!*])(?=\S+$).{8,}$/,
                'Password must be a minimum of 8 characters and should contain at least one uppercase and lowercase letter, a number, and a special character.',
              )
              .required('Please enter your Password.'),
            conNewPass: Yup.string()
              .required('Please enter your confirm Password.')
              .oneOf([Yup.ref('newPass'), null], 'Passwords must match.'),
          }
        : {
            newPass: Yup.string()
              .matches(/^.{8,}$/, 'Password must be a minimum of 8 characters')
              .required('Please enter your Password.'),
            conNewPass: Yup.string()
              .required('Please enter your confirm Password.')
              .oneOf([Yup.ref('newPass'), null], 'Passwords must match.'),
          },
    ),

    onSubmit: (values, {setStatus, setSubmitting}) => {
      // Your login logic here using the form values
      // For example, you can authenticate the user and navigate upon successful login
      const {newPass, conNewPass} = values;

      const body = {
        email: email,
        newPassword: newPass,
      };

      setLoading(true);
      try {
        fetchDataFromAPI(`user/updatePassword`, 'post', body, token)
          .then((response) => {
            console.log('response', response);
            setLoading(false);

            navigate('/login');
            toast.success('Your password was reset succesfully. ✅', {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            formik.resetForm();
            setLoading(false);

            // navigate('/login');
          })
          .catch((error) => {
            setLoading(false);
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            console.log('error', error);
          });
      } catch (error) {
        setLoading(false);
        console.log('error', error);
      }
    },
  });

  return (
    <main>
      {loading && <Processing />}

      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center ">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <Link
                    to="/login"
                    className="logo d-flex align-items-center w-auto">
                    <img src={logo} alt="logo" height={'100%'} width={'100%'} />
                  </Link>
                </div>
                {/* <!-- End Logo --> */}
              </div>
              <div className="col-lg-6">
                <div className="card mb-3">
                  <div className="card-body">
                    <div className=" pb-2">
                      <span className="card-title text-center d-none d-lg-block">
                        Lynk Driver
                      </span>
                      <h5 className="card-title text-center pb-0 fs-4">
                        Change Password to Your Account
                      </h5>
                      {/* <p className="text-center small">Enter your email</p> */}
                    </div>

                    <form
                      className="row g-3 needs-validation"
                      onSubmit={formik.handleSubmit}
                      noValidate>
                      <div>
                        {/*username field*/}
                        <div className="col-12">
                          <label htmlFor="newPass" className="form-label">
                            New Password
                          </label>
                          <div className="input-group has-validation">
                            <input
                              placeholder="Enter new password"
                              type={showNewPass ? 'text' : 'password'}
                              name="newPass"
                              className={`form-control ${
                                formik.touched.newPass && formik.errors.newPass
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              style={{
                                borderTopRightRadius: '0',
                                borderBottomRightRadius: '0',
                              }}
                              id="newPass"
                              value={formik.values.newPass}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'newPass',
                                  e.target.value?.trimStart(),
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              style={{
                                borderTopLeftRadius: '0', // Adjust the radius value
                                borderBottomLeftRadius: '0', // Adjust the radius value
                              }}
                              onClick={() => setShowNewPass(!showNewPass)}>
                              {showNewPass ? (
                                <i className="bi bi-eye-fill"></i>
                              ) : (
                                <i className="bi bi-eye-slash-fill"></i>
                              )}
                            </button>
                            {formik.touched.newPass &&
                              formik.errors.newPass && (
                                <div className="invalid-feedback">
                                  {formik.errors.newPass}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-12 mt-3">
                          <label htmlFor="conPass" className="form-label">
                            Confirm Password
                          </label>
                          <div className="input-group has-validation">
                            <input
                              placeholder="Confirm password"
                              type={showConNewPass ? 'text' : 'password'}
                              name="conNewPass"
                              className={`form-control ${
                                formik.touched.conNewPass &&
                                formik.errors.conNewPass
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              style={{
                                borderTopRightRadius: '0',
                                borderBottomRightRadius: '0',
                              }}
                              id="conNewPass"
                              value={formik.values.conNewPass}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'conNewPass',
                                  e.target.value?.trimStart(),
                                );
                              }}
                              onBlur={formik.handleBlur}
                            />
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              style={{
                                borderTopLeftRadius: '0', // Adjust the radius value
                                borderBottomLeftRadius: '0', // Adjust the radius value
                              }}
                              onClick={() =>
                                setShowConNewPass(!showConNewPass)
                              }>
                              {showConNewPass ? (
                                <i className="bi bi-eye-fill"></i>
                              ) : (
                                <i className="bi bi-eye-slash-fill"></i>
                              )}
                            </button>
                            {formik.touched.conNewPass &&
                              formik.errors.conNewPass && (
                                <div className="invalid-feedback">
                                  {formik.errors.conNewPass}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      {/*submit button*/}
                      <div className="col-12">
                        <button
                          className="btn btn-warning w-100"
                          type="submit"
                          // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          {!loading && (
                            <span className="indicator-label">Continue</span>
                          )}
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{display: 'block'}}>
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default FormNewPass;
