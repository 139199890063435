const Processing = () => {
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    background: 'linear-gradient(90deg, #fdc108 0%, #fdc108 100%)',
    color: '#000000',
    fontWeight: '500',
    width: 'auto',
    padding: '1rem 2rem',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99999,
  };

  return (
    <div
      style={{position: 'relative'}}
      className="d-flex justify-content-center">
      <div
        style={{
          ...styles,
          width: 150,
          backgroundColor: 'white',
          position: 'absolute',
          top: 200,
        }}>
        Processing...
      </div>
    </div>
  );
};

export default Processing;
