import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import constants, {getUser} from '../../utils/constants';
import {base_url, fetchDataFromAPI} from '../../utils/api';
import {CSVLink} from 'react-csv';
import {Button, Modal, Pagination} from 'react-bootstrap';
import moment from 'moment';
import Processing from '../../components/Processing/Processing';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import axios from 'axios';
import fileDownload from 'js-file-download';

const Driver = () => {
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDriverId, setSelectedDriverId] = useState(null);

  const [selectedDeviceType, setSelectedDeviceType] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedTypeSignUp, setSelectedSignUp] = useState(null);

  const [sortByFieldName, setSortByFieldName] = useState('first_name');
  const [sortOrder, setSortOrder] = useState('ASC');

  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  console.log('currentPage', currentPage);

  const [totalPages, setTotalPages] = useState(1); // Initialize total pages

  const [sortByFirstName, setSortByFirstName] = useState('ASC');

  const [sortByLastName, setSortByLastName] = useState('ASC');

  const [selectedAppType, setSelectedAppType] = useState('0');

  useEffect(() => {
    getDrivers(currentPage);
  }, [
    currentPage,
    selectedDeviceType,
    selectedType,
    selectedTypeSignUp,
    selectedAppType,
  ]);

  // ... Rest of your existing code ...
  const user = JSON.parse(getUser());

  const getCsv = async () => {
    const params = {
      id: user?.user_id,
      device_type: selectedDeviceType ? selectedDeviceType : null,
      clicked_to_app: selectedType,
      // page: currentPage,
      sortOrder: 'DESC',
      firstName: formik.values.firstname,
      lastName: formik.values.lastname,
      email: formik.values.email,
      mobile: formik.values.mobile,
      spsv: formik.values.spsv,
      registrationComplete: selectedTypeSignUp,
      icabbiStatus: selectedAppType,
    };

    console.log('params', params);

    try {
      const response = await axios.get(`${base_url}admin/exportSearchedUser`, {
        params: params,
        headers: {
          Accept: 'text/csv',
          'Content-Type': 'text/csv',
          Authorization: `Bearer ${user?.authToken}`,
        },
        responseType: 'blob',
      });

      console.log('response', response.data);

      fileDownload(response.data, 'data.csv');
      // Open the URL in a new window
      // if (url) {
      //   window.open(url, '_blank');
      // } else {
      //   console.log('URL not found in the response');
      // }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getDrivers = async (pageNumber) => {
    setLoading(true);
    const params = {
      id: user?.user_id,
      uid: formik.values.id,
      device_type: selectedDeviceType,
      // sortField: sortByFieldName,
      sortOrder: 'DESC',
      clicked_to_app: selectedType,
      page: pageNumber,
      firstName: formik.values.firstname,
      lastName: formik.values.lastname,
      email: formik.values.email,
      mobile: formik.values.mobile,
      spsv: formik.values.spsv,
      registrationComplete: selectedTypeSignUp,
      icabbiStatus: selectedAppType,
    };

    axios({
      url: `${base_url}admin/users`,
      params: params,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user?.authToken}`,
      },
    })
      // fetchDataFromAPI(`admin/users`, 'post', body, '')
      .then((response) => {
        setDrivers(response?.data?.data);
        setTotalPages(response?.data?.totalPages);

        console.log('response', response?.data);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // if (error?.response?.data?.status === 500) {
        //   localStorage.removeItem(constants.USER);
        //   navigate('/login');
        // }
        // toast.error(error?.response?.data?.message, {
        //   position: toast.POSITION.TOP_RIGHT,
        //   className: 'bg-danger text-white',
        // });

        console.log('error', error);
      });
    // fetchDataFromAPI(
    //   `admin/users?device_type=&page=${pageNumber}&id=${user?._id}`,
    //   'get',
    //   '',
    //   user?.authToken,
    // )
    //   .then((res) => {
    //     console.log('users res: ', res);
    //     setDrivers(res?.data);
    //     setTotalPages(res?.totalPages); // Set total pages from API response
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //     console.log('users error: ', error);
    //   });
  };

  const formik = useFormik({
    initialValues: {
      id: '',
      firstname: '',
      lastname: '',
      mobile: '',
      email: '',
      spsv: '',
    },
    validationSchema: Yup.object({
      id: Yup.string().notRequired(),
      firstname: Yup.string().notRequired(),
      lastname: Yup.string().notRequired(),
      mobile: Yup.string().notRequired(),
      // email: Yup.string().email('Invalid email format').notRequired(),
      email: Yup.string().notRequired(),
      spsv: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      const {firstname, lastname, mobile, email, spsv, id} = values;

      const user = JSON.parse(getUser());
      getDrivers(1);
    },
  });

  const handleSortBy = (field) => {
    const order =
      field === sortByFieldName && sortOrder === 'ASC' ? 'DESC' : 'ASC';
    setSortOrder(order);
    setSortByFieldName(field);

    // Perform sorting based on the selected field
    const sortedDrivers = [...drivers].sort((a, b) => {
      const fieldA = a[field].toLowerCase();
      const fieldB = b[field].toLowerCase();

      if (order === 'ASC') {
        return fieldA.localeCompare(fieldB);
      } else {
        return fieldB.localeCompare(fieldA);
      }
    });

    // Update the state with the sorted data
    setDrivers(sortedDrivers);
  };

  const deleteDrivers = async () => {
    const user = JSON.parse(getUser());
    const params = {
      id: selectedDriverId,
    };
    try {
      const response = await axios.delete(`${base_url}admin/deleteUser`, {
        params: params,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.authToken}`,
        },
      });
      toast.success('Driver delete successfully ✅', {
        position: toast.POSITION.TOP_RIGHT,
        className: 'bg-success text-white',
      });
      setShowDeleteModal(false);
      getDrivers();
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        className: 'bg-danger text-white',
      });
      console.log('error', error);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    // getSneakerData(searchTerm?.toLowerCase(), pageNumber)
  };

  const handlePrevious = () => {
    if (currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
      getDrivers(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((prev) => prev + 1);
      getDrivers(currentPage + 1);
    }
  };

  const getPaginationItems = () => {
    const paginationItems = [];
    // console.log(total)

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 2) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            onClick={() => handlePageClick(i)}
            active={i === currentPage}
            role="button">
            {i}
          </Pagination.Item>,
        );
      } else if (paginationItems[paginationItems.length - 1] !== '...') {
        paginationItems.push('...');
      }
    }

    return paginationItems;
  };

  // Function to handle sorting by First Name
  const handleSortByFirstName = () => {
    const order = sortByFirstName === 'ASC' ? 'DESC' : 'ASC';
    setSortByFirstName(order);
    setSortByLastName('ASC'); // Reset sorting for other columns if needed

    // Perform sorting based on First Name
    const sortedDrivers = [...drivers].sort((a, b) => {
      if (order === 'ASC') {
        return a.first_name.localeCompare(b.first_name);
      } else {
        return b.first_name.localeCompare(a.first_name);
      }
    });

    // Update the state with the sorted data
    setDrivers(sortedDrivers);
  };

  // Function to handle sorting by Last Name
  const handleSortByLastName = () => {
    const order = sortByLastName === 'ASC' ? 'DESC' : 'ASC';
    setSortByLastName(order);
    setSortByFirstName('ASC'); // Reset sorting for other columns if needed

    // Perform sorting based on Last Name
    const sortedDrivers = [...drivers].sort((a, b) => {
      if (order === 'ASC') {
        return a.last_name.localeCompare(b.last_name);
      } else {
        return b.last_name.localeCompare(a.last_name);
      }
    });

    // Update the state with the sorted data
    setDrivers(sortedDrivers);
  };

  const showDeleteConfirmation = (id) => {
    setSelectedDriverId(id);
    setShowDeleteModal(true);
  };

  const handleLynkAppClick = () => {
    setSelectedAppType('0');
  };

  // Event handler for selecting Lynk & iCabbi App
  const handleLynkiCabbiAppClick = () => {
    setSelectedAppType('1');
  };

  return (
    <div id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Drivers</li>
          </ol>
        </nav>
      </div>
      <div className="card">
        <form className="mx-3" onSubmit={formik.handleSubmit}>
          <div className="row mb-3"></div>
          <div className="row mb-3 align-items-end">
            <div className="col-md-1">
              <label htmlFor="id" className="form-label">
                ID
              </label>
              <input
                name="id"
                type="text"
                className="form-control"
                id="id"
                onChange={(e) => {
                  formik.setFieldValue('id', e.target.value?.trimStart());
                }}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="firstName" className="form-label">
                First Name
              </label>
              <input
                name="firstName"
                type="text"
                className="form-control"
                id="firstName"
                onChange={(e) => {
                  formik.setFieldValue(
                    'firstname',
                    e.target.value?.trimStart(),
                  );
                }}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
              <input
                name="lastName"
                type="text"
                className="form-control"
                id="lastName"
                onChange={(e) => {
                  formik.setFieldValue('lastname', e.target.value?.trimStart());
                }}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="Mobile" className="form-label">
                Mobile
              </label>
              <input
                name="Mobile"
                type="text"
                className="form-control"
                id="Mobile"
                // onChange={(e) => {
                //   formik.setFieldValue('mobile', e.target.value?.trimStart());
                // }}
                onChange={(e) => {
                  const trimmedValue = e.target.value.trim(); // Trim the input value
                  formik.setFieldValue('mobile', trimmedValue);
                }}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="Email" className="form-label">
                Email
              </label>
              <input
                name="Email"
                type="text"
                className="form-control"
                id="Email"
                onChange={(e) => {
                  formik.setFieldValue('email', e.target.value?.trimStart());
                }}
              />
            </div>
            <div className="col-md-1">
              <label htmlFor="spsv" className="form-label">
                SPSV
              </label>
              <input
                name="spsv"
                type="text"
                className="form-control"
                id="spsv"
                onChange={(e) => {
                  formik.setFieldValue('spsv', e.target.value?.trimStart());
                }}
              />
            </div>
            <div className="col-md-2 text-center ">
              <div className="d-flex gap-2 align-items-center">
                <button
                  type="submit"
                  className="btn btn-warning w-100 btn-serach">
                  Search
                </button>
                <div onClick={() => getCsv()}>
                  {/* <CSVLink data={csv} headers={headers} filename={'users.csv'}> */}
                  {/* <i className="bi bi-share"></i> */}
                  <img
                    role="button"
                    style={{height: 20, width: 25}}
                    src={require('../../assets/images/export.png')}
                    alt="logo"
                  />
                  {/* </CSVLink> */}
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="card-body">
          {/* <h3 className=" font-weight-bold">Drivers List</h3> */}
          {/* <hr /> */}

          <ul className="nav nav-tabs nav-tabs-bordered">
            <li className="nav-item">
              <button
                className={`nav-link ${
                  selectedAppType === '0' ? 'active' : ''
                }`}
                onClick={handleLynkAppClick}
                data-bs-toggle="tab"
                data-bs-target="#driver-app">
                Lynk App
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${
                  selectedAppType === '1' ? 'active' : ''
                }`}
                onClick={handleLynkiCabbiAppClick}
                data-bs-toggle="tab"
                data-bs-target="#driver-icabbi">
                Lynk & iCabbi App
              </button>
            </li>
          </ul>
          {/* <!-- Default Table --> */}
          <div className="tab-content pt-2 ">
            <div className="table-responsive">
              <table className="table text-nowrap align-items-end table-hover">
                <thead>
                  <tr className="">
                    <th scope="col">Id</th>
                    <th
                      scope="col"
                      role="button"
                      onClick={() => handleSortBy('first_name')}>
                      First Name{' '}
                      {sortByFieldName === 'first_name' && sortOrder === 'ASC'
                        ? '▲'
                        : '▼'}
                    </th>

                    <th
                      scope="col"
                      role="button"
                      onClick={() => handleSortBy('last_name')}>
                      Last Name{' '}
                      {sortByFieldName === 'last_name' && sortOrder === 'ASC'
                        ? '▲'
                        : '▼'}
                    </th>
                    <th scope="col">Mobile</th>
                    <th scope="col">Email</th>
                    <th scope="col">SPSV</th>
                    {/* <th scope="col">Android/iOS</th> */}
                    <th scope="col">
                      <select
                        className="selectapp"
                        value={selectedDeviceType}
                        onChange={(e) => setSelectedDeviceType(e.target.value)}>
                        <option value="">Android/iOS</option>
                        <option value="Android">Android</option>
                        <option value="iOS">IOS</option>
                      </select>
                    </th>

                    <th scope="col">Registration Timestamp</th>

                    <th scope="col">
                      <select
                        className="selectapp"
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}>
                        <option value="">Clicked to App (Yes/No)</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </th>
                    <th scope="col">Agreement Version</th>
                    <th scope="col">Date Accepted</th>
                    <th scope="col">Sign Up Complete (Yes/No)</th>
                    {/* <th scope="col">
                    <select
                      className="selectapp"
                      value={selectedTypeSignUp}
                      onChange={(e) => setSelectedSignUp(e.target.value)}>
                      <option value="">Sign Up Complete (Yes/No)</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </th> */}

                    <th scope="col">iCabbi</th>
                    <th scope="col" className="text-center">
                      Action
                    </th>
                    {/* <th scope="col">View</th> */}
                  </tr>
                </thead>
                <tbody>
                  {drivers.map((item, index) => {
                    const isAgreementVerified =
                      item.agreement_verified === true;
                    const isDocumentUploaded = item.document_uploaded === true;
                    const displayValue =
                      isAgreementVerified && isDocumentUploaded ? 'Yes' : 'No';

                    const formattedDate = moment(item?.createdAt).format(
                      'YYYY-MM-DD HH:mm:ss a',
                    ); // Adjust the format as needed

                    const dareSignd = item?.agreement_signed
                      ? moment(item?.agreement_signed).format('DD-MM-YYYY')
                      : 'Not Accepted Yet ';

                    return (
                      <tr
                        className=""
                        role="button"
                        onClick={() =>
                          navigate(`/driver/view/${item?.user_id}`, {
                            state: {user: item},
                          })
                        }>
                        {/* <th scope="row">{index + 1}</th> */}

                        <td scope="row">{item?.user_id}</td>

                        <td>{item?.first_name}</td>
                        <td>{item?.last_name}</td>
                        <td>{item?.mobile_no ? item?.mobile_no : '-'}</td>
                        <td>{item?.email}</td>
                        <td>{item?.spsv}</td>
                        <td>{item?.device_type}</td>
                        <td>{formattedDate}</td>
                        <td className="text-center">
                          {item?.clicked_to_app ? item?.clicked_to_app : 'No'}
                        </td>
                        <td>
                          {item.agreement_version
                            ? item.agreement_version
                            : '-'}
                        </td>
                        <td>{dareSignd}</td>
                        <td className="text-center">
                          {item?.registrationComplete}
                        </td>

                        <td className="text-center">
                          {item?.icabbiStatus === 0 ? 'Inactive' : 'Active'}
                        </td>
                        <td className="text-center">
                          <div className="d-flex justify-content-center ">
                            <div
                              className="me-2 "
                              role="button"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevents event propagation to the row click
                                navigate(`/driver/edit/${item?.user_id}`, {
                                  state: {user: item},
                                });
                              }}>
                              <i class="bi bi-pencil-square"></i>
                            </div>
                            <div
                              role="button"
                              className="me-2"
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(`/driver/view/${item?.user_id}`, {
                                  state: {user: item},
                                });
                              }}>
                              <i class="bi bi-eye"></i>
                            </div>
                            {user?.type === 'super_admin' && (
                              <div
                                role="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  showDeleteConfirmation(item?.user_id);
                                }}
                                className="">
                                <i className="bi bi-trash"></i>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {/* <!-- End Default Table Example --> */}
        </div>

        <Modal
          show={showDeleteModal}
          centered
          onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body className="">
            <p className="m-0">
              Are you sure you'd like to delete this driver and all their
              records?
            </p>
          </Modal.Body>
          <Modal.Footer className="text-end gap-1 border-0 px-3 py-2">
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={() => deleteDrivers()}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* <div className="col align-items-center justify-content-center   text-center ">
          {filteredDrivers?.length == 0 && (
            <h3 className="text-center ">No Data Found</h3>
          )}
        </div> */}
        {/* Pagination */}
        {totalPages > 1 && (
          <Pagination className="align-items-center justify-content-center">
            {currentPage !== 1 && ( // Check if it's not the first page
              <p
                className="text-center mb-sm-0 pe-3 cursor-pointer"
                onClick={handlePrevious}
                role="button">
                Previous
              </p>
            )}
            {getPaginationItems()}
            {currentPage !== totalPages &&
              drivers.length > 0 && ( // Check if it's not the last page and there's data
                <p
                  className="text-center mb-sm-0 ps-3 cursor-pointer"
                  onClick={handleNext}
                  role="button">
                  Next
                </p>
              )}
          </Pagination>
        )}

        {totalPages <= 1 && drivers?.length === 0 && (
          <p className="text-center">No data available</p>
        )}
      </div>
    </div>
  );
};

export default Driver;
