import React from 'react';
import {useLocation} from 'react-router-dom';

const Sidebar = () => {
  const {pathname} = useLocation();
  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className="nav-item">
          <a
            className={pathname === '/' ? 'nav-link' : 'nav-link collapsed'}
            href="/">
            <i className="bi bi-grid" />
            <span>Dashboard</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            className={
              pathname.includes('/driver') ? 'nav-link' : 'nav-link collapsed'
            }
            href="/driver">
            <i class="bi bi-people" /> <span>Drivers</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            className={
              pathname.includes('/agreement')
                ? 'nav-link'
                : 'nav-link collapsed'
            }
            href="/agreement">
            <i class="bi bi-file-earmark" />
            <span>Driver Agreement</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            className={
              pathname.includes('/managereports')
                ? 'nav-link'
                : 'nav-link collapsed'
            }
            href="/managereports">
            <i class="bi bi-flag" />
            <span>Reports</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            className={
              pathname.includes('/users') ? 'nav-link' : 'nav-link collapsed'
            }
            href="/users">
            <i class="bi bi-person-circle" />
            <span>Users</span>
          </a>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
