import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {base_url, fetchDataFromAPI} from '../../utils/api';
import constants, {getUser} from '../../utils/constants';
import Processing from '../../components/Processing/Processing';
import moment from 'moment';
import {CSVLink} from 'react-csv';
import {toast} from 'react-toastify';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {Modal, Pagination, Button} from 'react-bootstrap';
import fileDownload from 'js-file-download';

const DriverAgrement = () => {
  const [loading, setLoading] = useState(false);
  const [driversAgrement, setDriverAgreement] = useState([]);
  const [agreement, setAgreement] = useState('');

  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 10; // Assuming 10 items per page based on your structure
  const [totalPages, setTotalPages] = useState(1); // Initialize total pages

  const navigate = useNavigate();

  const [sortByFirstName, setSortByFirstName] = useState('asc');
  const [sortByLastName, setSortByLastName] = useState('asc');

  useEffect(() => {
    getDriverAgreement(currentPage);
  }, [currentPage]);

  // const getAgreement = async () => {
  //   setLoading(true);
  //   const user = JSON.parse(getUser());
  //   try {
  //     const response = await fetchDataFromAPI(
  //       'Agreement',
  //       'get',
  //       '',
  //       user?.authToken,
  //     );
  //     console.log('response', response);

  //     setAgreement([response?.data]);
  //   } catch (error) {
  //     setLoading(false);

  //     console.log('error', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // get Driver Agrement
  const getDriverAgreement = async (pageNumber) => {
    setLoading(true);
    const user = JSON.parse(getUser());
    const params = {
      id: user?.user_id,
      uid: formik.values.id,
      // device_type: 'Android',
      sortOrder: 'DESC',
      page: pageNumber,
      firstName: formik.values.firstname,
      lastName: formik.values.lastname,
      email: formik.values.email,
      mobile: formik.values.mobile,
      spsv: formik.values.spsv,
    };
    axios({
      url: `${base_url}admin/users`,
      params: params,
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user?.authToken}`,
      },
    })
      // fetchDataFromAPI(
      //   `admin/users?device_type=&page=${1}&id=${user?._id}`,
      //   'get',
      //   '',
      //   user?.authToken,
      // )
      .then((res) => {
        console.log('res', res?.data);
        setDriverAgreement(res?.data?.data);
        setTotalPages(res?.data?.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.data?.status === 500) {
          localStorage.removeItem(constants.USER);
          navigate('/login');
        }
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-danger text-white',
        });
        console.log('users error: ', error);
      });
  };

  const formik = useFormik({
    initialValues: {
      id: '',
      firstname: '',
      lastname: '',
      mobile: '',
      email: '',
      spsv: '',
    },
    validationSchema: Yup.object({
      firstname: Yup.string().notRequired(),
      lastname: Yup.string().notRequired(),
      mobile: Yup.string().notRequired(),
      email: Yup.string().notRequired(),
      email: Yup.string().notRequired(),
      spsv: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      const {firstname, lastname, mobile, email, spsv} = values;
      const user = JSON.parse(getUser());
      getDriverAgreement(1);
    },
  });

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    // getSneakerData(searchTerm?.toLowerCase(), pageNumber)
  };

  const handlePrevious = () => {
    if (currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
      getDriverAgreement(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage !== totalPages) {
      setCurrentPage((prev) => prev + 1);
      getDriverAgreement(currentPage + 1);
    }
  };

  const getPaginationItems = () => {
    const paginationItems = [];
    // console.log(total)

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 2) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            onClick={() => handlePageClick(i)}
            active={i === currentPage}
            role="button">
            {i}
          </Pagination.Item>,
        );
      } else if (paginationItems[paginationItems.length - 1] !== '...') {
        paginationItems.push('...');
      }
    }

    return paginationItems;
  };

  // Function to handle sorting by First Name
  const handleSortByFirstName = () => {
    const order = sortByFirstName === 'asc' ? 'desc' : 'asc';
    setSortByFirstName(order);
    setSortByLastName('asc'); // Reset sorting for other columns if needed

    // Perform sorting based on First Name
    const sortedDrivers = [...driversAgrement].sort((a, b) => {
      if (order === 'asc') {
        return a.first_name.localeCompare(b.first_name);
      } else {
        return b.first_name.localeCompare(a.first_name);
      }
    });

    // Update the state with the sorted data
    setDriverAgreement(sortedDrivers);
  };

  // Function to handle sorting by Last Name
  const handleSortByLastName = () => {
    const order = sortByLastName === 'asc' ? 'desc' : 'asc';
    setSortByLastName(order);
    setSortByFirstName('asc'); // Reset sorting for other columns if needed

    // Perform sorting based on Last Name
    const sortedDrivers = [...driversAgrement].sort((a, b) => {
      if (order === 'asc') {
        return a.last_name.localeCompare(b.last_name);
      } else {
        return b.last_name.localeCompare(a.last_name);
      }
    });

    // Update the state with the sorted data
    setDriverAgreement(sortedDrivers);
  };

  const user = JSON.parse(getUser());
  const getCsv = async () => {
    const params = {
      id: user?.user_id,
      uid: formik.values.id,
      // page: currentPage,
      sortOrder: 'DESC',
      firstName: formik.values.firstname,
      lastName: formik.values.lastname,
      mobile: formik.values.mobile,
      email: formik.values.email,
      spsv: formik.values.spsv,
    };

    try {
      const response = await axios.get(`${base_url}admin/exportSearchedUser`, {
        params: params,
        headers: {
          Accept: 'text/csv',
          'Content-Type': 'text/csv',
          Authorization: `Bearer ${user?.authToken}`,
        },
        responseType: 'blob',
      });

      console.log('response', response); // Assuming this contains the URL
      fileDownload(response.data, 'data.csv');
      // Open the URL in a new window
      // if (url) {
      //   window.open(url, '_blank');
      // } else {
      //   console.log('URL not found in the response');
      // }
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <div id="main" className="main">
      {loading && <Processing />}

      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Driver Agreement</li>
          </ol>
        </nav>
      </div>
      <div className="card">
        <div className="row mb-3" />
        <div className=" d-flex align-items-center justify-content-end me-3">
          <div className=" d-flex">
            <div className="col-md-12 d-flex align-items-center ">
              <div onClick={() => getCsv()} className="me-2">
                <img
                  role="button"
                  style={{height: 20, width: 25}}
                  src={require('../../assets/images/export.png')}
                  alt="logo"
                />
              </div>

              <button
                type="submit"
                className="btn btn-warning w-100"
                onClick={() => navigate('/editagreement')}>
                Edit Agreement
              </button>
            </div>
          </div>
        </div>
        <form className="mx-3" onSubmit={formik.handleSubmit}>
          <div className="row mb-3"></div>
          <div className="row mb-3 align-items-end">
            <div className="col-md-1">
              <label htmlFor="id" className="form-label">
                ID
              </label>
              <input
                name="id"
                type="text"
                className="form-control "
                id="id"
                onChange={(e) => {
                  formik.setFieldValue('id', e.target.value?.trimStart());
                }}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="firstName" className="form-label">
                First Name
              </label>
              <input
                name="firstName"
                type="text"
                className="form-control"
                id="firstName"
                onChange={(e) => {
                  formik.setFieldValue(
                    'firstname',
                    e.target.value?.trimStart(),
                  );
                }}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
              <input
                name="lastName"
                type="text"
                className="form-control"
                id="lastName"
                onChange={(e) => {
                  formik.setFieldValue('lastname', e.target.value?.trimStart());
                }}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="Mobile" className="form-label">
                Mobile
              </label>
              <input
                name="Mobile"
                type="text"
                className="form-control"
                id="Mobile"
                // onChange={(e) => {
                //   formik.setFieldValue('mobile', e.target.value?.trimStart());
                // }}
                onChange={(e) => {
                  const trimmedValue = e.target.value.trim(); // Trim the input value
                  formik.setFieldValue('mobile', trimmedValue);
                }}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="Email" className="form-label">
                Email
              </label>
              <input
                name="Email"
                type="text"
                className="form-control"
                id="Email"
                onChange={(e) => {
                  formik.setFieldValue('email', e.target.value?.trimStart());
                }}
              />
            </div>
            <div className="col-md-1">
              <label htmlFor="spsv" className="form-label">
                SPSV
              </label>
              <input
                name="spsv"
                type="text"
                className="form-control"
                id="spsv"
                onChange={(e) => {
                  formik.setFieldValue('spsv', e.target.value?.trimStart());
                }}
              />
            </div>
            <div className="col-md-2 text-center ">
              <button
                type="submit"
                className="btn btn-warning w-100 btn-serach">
                Search
              </button>
            </div>
          </div>
        </form>
        <div className="card-body">
          <h3 className=" font-weight-bold">Drivers Agreement List</h3>

          <hr />
          {/* <!-- Default Table --> */}
          <div className="table-responsive">
            <table className="table text-nowrap align-items-end table-hover">
              <thead>
                <tr className="">
                  <th scope="col">Id</th>
                  <th
                    scope="col "
                    role="button"
                    onClick={handleSortByFirstName}>
                    First Name {sortByFirstName === 'asc' ? '▲' : '▼'}
                  </th>

                  <th scope="col" role="button" onClick={handleSortByLastName}>
                    Last Name {sortByLastName === 'asc' ? '▲' : '▼'}
                  </th>
                  <th scope="col">Mobile</th>
                  <th scope="col">Email</th>
                  <th scope="col">SPSV</th>

                  <th scope="col">Agreement Version</th>
                  <th scope="col">Date Accepted</th>
                </tr>
              </thead>
              <tbody>
                {driversAgrement.map((item, index) => {
                  const isAgreementVerified = item?.agreement_verified === true;
                  const isDocumentUploaded = item?.document_uploaded === true;
                  const displayValue =
                    isAgreementVerified && isDocumentUploaded
                      ? item.agreement_version
                      : '-';

                  const dareSignd =
                    item?.agreement_signed && item.agreement_signed !== ''
                      ? moment(item.agreement_signed).format('DD-MM-YYYY')
                      : 'Not Accepted Yet';

                  // const dareSignd = item?.last_login
                  //   ? moment(item?.agreement_signed).format('DD-MM-YYYY')
                  //   : 'Not signed yet';

                  return (
                    <tr className="">
                      <td scope="row">{item?.user_id}</td>
                      <td>{item?.first_name}</td>
                      <td>{item?.last_name}</td>
                      <td>{item?.mobile_no ? item?.mobile_no : '-'}</td>
                      <td>{item?.email}</td>
                      <td>{item?.spsv}</td>

                      <td>
                        {item.agreement_version ? item.agreement_version : '-'}
                      </td>
                      <td>{dareSignd}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <!-- End Default Table Example --> */}
        </div>
        {/* Pagination */}
        {totalPages > 1 && (
          <Pagination className="align-items-center justify-content-center">
            {currentPage !== 1 && ( // Check if it's not the first page
              <p
                className="text-center mb-sm-0 pe-3 cursor-pointer"
                onClick={handlePrevious}
                role="button">
                Previous
              </p>
            )}
            {getPaginationItems()}
            {currentPage !== totalPages &&
              driversAgrement?.length > 0 && ( // Check if it's not the last page and there's data
                <p
                  className="text-center mb-sm-0 ps-3 cursor-pointer"
                  onClick={handleNext}
                  role="button">
                  Next
                </p>
              )}
          </Pagination>
        )}
        {totalPages <= 1 && driversAgrement?.length === 0 && (
          <p className="text-center">No data available</p>
        )}
      </div>
    </div>
  );
};

export default DriverAgrement;
