import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {fetchDataFromAPI} from '../../utils/api';
import {getUser, saveUserLocally} from '../../utils/constants';
import {toast} from 'react-toastify';
import Processing from '../../components/Processing/Processing';

const EditProfile = () => {
  const user = JSON.parse(getUser());
  console.log('user', user);

  const [selectedRole, setSelectedRole] = useState('');
  const [loading, setLoading] = useState(false);

  const [showNewPass, setShowNewPass] = useState(false);
  const [showConNewPass, setShowConNewPass] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    setLoading(true);
    formik.setFieldValue('firstname', user?.first_name);
    formik.setFieldValue('lastname', user?.last_name);
    formik.setFieldValue('email', user?.email);
    formik.setFieldValue('role', user?.type);

    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      newPass: '',
      conNewPass: '',
    },

    validationSchema: Yup.object({
      firstname: Yup.string().required('First Name is required'),
      lastname: Yup.string().required('Last Name is required'),
      email: Yup.string().email('Invalid email').required('Email is required'),
      role: Yup.string().notRequired(),
      newPass: Yup.string()
        .matches(
          /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[.@#$%^&+=!*])(?=\S+$).{8,}$/,
          'Password must be a minimum of 8 characters and should contain at least one uppercase and lowercase letter, a number, and a special character.',
        )
        .notRequired(),
      conNewPass: Yup.string()

        .oneOf([Yup.ref('newPass'), null], 'Password does must match.')
        .notRequired(),
    }),

    onSubmit: (values, {setStatus, setSubmitting}) => {
      const {firstname, lastname, email, conNewPass, role, newPass} = values;

      try {
        if (newPass && !conNewPass) {
          formik.setFieldError(
            'conNewPass',
            'Please enter repeat new password',
          );
          return;
        }
        setLoading(true);
        // const body = {
        //   firstName: firstName,
        //   lastName: lastName,
        //   email: email,
        // };

        const data = {
          id: user?.user_id,
          first_name: firstname,
          last_name: lastname,
          email: email,
          type: role,
          password: conNewPass,
        };

        fetchDataFromAPI('admin/profileUpdate', 'post', data, user?.authToken)
          .then((response) => {
            toast.success('Profile updated successfully ✅', {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });

            saveUserLocally(JSON.stringify(response?.data));
            setLoading(false);
            navigate('/');
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            setLoading(false);

            console.log('catch error:', error);
            console.log('type:', typeof error?.response?.data);
            if (
              error?.response?.data &&
              typeof error?.response?.data === 'string'
            ) {
              setStatus('');
              setSubmitting(false);
              setLoading(false);
              alert(error?.response?.data);
              return;
            }
            setStatus(' details are incorrect');
            setSubmitting(false);
            setLoading(false);
          });
      } catch (error) {
        console.log('catch error: ', error);
        setStatus('The login details are incorrect');
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  // Event handler to update the selected value
  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };
  return (
    <>
      <main id="main" className="main">
        {loading && <Processing />}

        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>

              <li className="breadcrumb-item active">Profile</li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section profile">
          <div className="row">
            <div className="card">
              <div className="card-body pt-3">
                {/* Bordered Tabs */}
                <ul className="nav nav-tabs nav-tabs-bordered">
                  <li className="nav-item">
                    <button
                      className="nav-link active"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-overview">
                      Overview
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-edit">
                      Edit Profile
                    </button>
                  </li>
                </ul>
                <div className="tab-content pt-2">
                  <div
                    className="tab-pane fade show active profile-overview"
                    id="profile-overview">
                    <h5 className="card-title mt-3">Profile Details</h5>
                    <hr />{' '}
                    <div className="row">
                      <div className="col-lg-3 col-md-4 label ">Fist Name</div>
                      <div className="col-lg-9 col-md-8">
                        {user?.first_name}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-4 label">Last Name</div>
                      <div className="col-lg-9 col-md-8">{user?.last_name}</div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-4 label">Email</div>
                      <div className="col-lg-9 col-md-8">{user?.email}</div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-4 label">Role</div>
                      <div className="col-lg-9 col-md-8">
                        {user?.type === 'super_admin' ? 'Super Admin' : 'Admin'}
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade profile-edit pt-3"
                    id="profile-edit">
                    {/* Profile Edit Form */}
                    <form
                      className="needs-validation"
                      onSubmit={formik.handleSubmit}>
                      <div className="row mb-3">
                        <label
                          htmlFor="firstName"
                          className="col-md-4 col-lg-3 col-form-label">
                          First Name
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <input
                            name="firstName"
                            type="text"
                            className={`form-control ${
                              formik.touched.firstname &&
                              formik.errors.firstname
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="firstName"
                            value={formik.values.firstname}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'firstname',
                                e.target.value?.trimStart(),
                              );
                            }}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.firstname &&
                            formik.errors.firstname && (
                              <div className="invalid-feedback">
                                {formik.errors.firstname}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="lastName"
                          className="col-md-4 col-lg-3 col-form-label">
                          Last Name
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <input
                            name="lastName"
                            type="text"
                            className={`form-control ${
                              formik.touched.lastname && formik.errors.lastname
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="lastName"
                            value={formik.values.lastname}
                            // onChange={formik.handleChange}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'lastname',
                                e.target.value?.trimStart(),
                              );
                            }}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.lastname &&
                            formik.errors.lastname && (
                              <div className="invalid-feedback">
                                {formik.errors.lastname}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="email"
                          className="col-md-4 col-lg-3 col-form-label">
                          Email
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <input
                            name="email"
                            type="text"
                            className={`form-control ${
                              formik.touched.email && formik.errors.email
                                ? 'is-invalid'
                                : ''
                            }`}
                            value={formik.values.email}
                            id="email"
                            onChange={(e) => {
                              formik.setFieldValue(
                                'email',
                                e.target.value?.trimStart(),
                              );
                            }}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.email && formik.errors.email && (
                            <div className="invalid-feedback">
                              {formik.errors.email}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="Country"
                          className="col-md-4 col-lg-3 col-form-label">
                          Role
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <select
                            name="Role"
                            className="form-select"
                            id="Role"
                            {...formik.getFieldProps('role')}
                            disabled={user?.email === user?.email}
                            aria-label="Select Role">
                            {/* <option value="">Select a role...</option> */}
                            <option value="super_admin">Super Admin</option>
                            <option value="admin">Admin</option>

                            {/* <!-- Add more options as needed --> */}
                          </select>
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="firstName" className="form-label">
                            New Password
                          </label>
                          <div className="d-flex">
                            <input
                              name="newPass"
                              type={showNewPass ? 'text' : 'password'}
                              className="form-control"
                              style={{
                                borderTopRightRadius: '0',
                                borderBottomRightRadius: '0',
                              }}
                              id="newPass"
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'newPass',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              style={{
                                borderTopLeftRadius: '0', // Adjust the radius value
                                borderBottomLeftRadius: '0', // Adjust the radius value
                              }}
                              onClick={() => setShowNewPass(!showNewPass)}>
                              {showNewPass ? (
                                <i className="bi bi-eye-fill"></i>
                              ) : (
                                <i className="bi bi-eye-slash-fill"></i>
                              )}
                            </button>
                          </div>
                          {formik.touched.newPass && formik.errors.newPass && (
                            <div className="text-danger">
                              {formik.errors.newPass}
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="lastName" className="form-label">
                            Repeat New Password
                          </label>
                          <div className="d-flex">
                            <input
                              name="conNewPass"
                              type={showConNewPass ? 'text' : 'password'}
                              className="form-control"
                              style={{
                                borderTopRightRadius: '0',
                                borderBottomRightRadius: '0',
                              }}
                              id="conNewPass"
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'conNewPass',
                                  e.target.value?.trimStart(),
                                );
                              }}
                            />
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              style={{
                                borderTopLeftRadius: '0', // Adjust the radius value
                                borderBottomLeftRadius: '0', // Adjust the radius value
                              }}
                              onClick={() =>
                                setShowConNewPass(!showConNewPass)
                              }>
                              {showConNewPass ? (
                                <i className="bi bi-eye-fill"></i>
                              ) : (
                                <i className="bi bi-eye-slash-fill"></i>
                              )}
                            </button>
                          </div>
                          {formik.touched.conNewPass &&
                            formik.errors.conNewPass && (
                              <div className="text-danger">
                                {formik.errors.conNewPass}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="text-center">
                        <button
                          // disabled={formik.isSubmitting || !formik.isValid}
                          type="submit"
                          className="btn btn-warning">
                          {!loading && (
                            <span className="indicator-label">
                              Save Updates
                            </span>
                          )}
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{display: 'block'}}>
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </form>
                    {/* End Profile Edit Form */}
                  </div>
                  <div className="tab-pane fade pt-3" id="profile-settings">
                    {/* Settings Form */}

                    {/* End settings Form */}
                  </div>
                </div>
                {/* End Bordered Tabs */}
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* End #main */}
    </>
  );
};

export default EditProfile;
