import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {fetchDataFromAPI} from '../../utils/api';
import {toast} from 'react-toastify';
import {getUser} from '../../utils/constants';
import Processing from '../../components/Processing/Processing';
const AddUser = () => {
  const [loading, setLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');

  const [showNewPass, setShowNewPass] = useState(false);
  const [showConNewPass, setShowConNewPass] = useState(false);

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      role: '',
      newpass: '',
      connewpass: '',
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required('Please enter first name.'),
      lastname: Yup.string().required('Please enter last name.'),
      role: Yup.string().required('Please select role.'),
      email: Yup.string()

        .email('Invalid email')
        .required('Please enter your email.'),
      newpass: Yup.string()
        .matches(
          /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[.@#$%^&+=!*])(?=\S+$).{8,}$/,
          'Password must be a minimum of 8 characters and should contain at least one uppercase and lowercase letter, a number, and a special character.',
        )
        .required('Please enter Password.'),
      connewpass: Yup.string()
        .oneOf(
          [Yup.ref('newpass'), null],
          'Password and repeat password do not match.',
        )
        .required('Please enter repeat password.'),
    }),
    onSubmit: (values, {setStatus, setSubmitting}) => {
      const {firstname, lastname, email, connewpass, role} = values;

      try {
        const formData = new FormData();
        setLoading(true);
        formData.append('first_name', firstname);
        formData.append('last_name', lastname);
        formData.append('email', email);
        formData.append('type', role);
        formData.append('device_type', 'Desktop');
        formData.append('password', connewpass);

        const user = JSON.parse(getUser());

        fetchDataFromAPI('register', 'post', formData, user?.authToken)
          .then((responce) => {
            toast.success('User added successfully ✅', {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            setLoading(false);

            navigate('/users');
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            setLoading(false);
            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);
        setLoading(false);
      }
    },
  });

  return (
    <main id="main" className="main">
      {loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/users">Users</Link>
            </li>
            <li className="breadcrumb-item active">Add Users</li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}
              {/* <ul className="nav nav-tabs nav-tabs-bordered ">
                <label>Add Name</label>
              </ul> */}
              <div className="tab-content">
                <div className="" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3"></div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="firstname" className="form-label">
                          First Name
                        </label>
                        <input
                          name="firstname"
                          type="text"
                          //   className="form-control"
                          id="firstname"
                          value={formik.values.firstname}
                          className={`form-control ${
                            formik.touched.firstname && formik.errors.firstname
                              ? 'is-invalid'
                              : ''
                          }`}
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'firstname',
                              e.target.value?.trimStart(),
                            );
                          }}
                        />
                        {formik.touched.firstname &&
                          formik.errors.firstname && (
                            <div className="text-danger">
                              {formik.errors.firstname}
                            </div>
                          )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="lastname" className="form-label">
                          Last Name
                        </label>
                        <input
                          name="lastname"
                          type="text"
                          className={`form-control ${
                            formik.touched.lastname && formik.errors.lastname
                              ? 'is-invalid'
                              : ''
                          }`}
                          onBlur={formik.handleBlur}
                          id="lastname"
                          value={formik.values.lastname}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'lastname',
                              e.target.value?.trimStart(),
                            );
                          }}
                        />
                        {formik.touched.lastname && formik.errors.lastname && (
                          <div className="text-danger">
                            {formik.errors.lastname}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <input
                          name="email"
                          type="text"
                          className={`form-control ${
                            formik.touched.email && formik.errors.email
                              ? 'is-invalid'
                              : ''
                          }`}
                          onBlur={formik.handleBlur}
                          id="email"
                          value={formik.values.email}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'email',
                              e.target.value?.trimStart(),
                            );
                          }}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="text-danger">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="Role" className="form-label">
                          Role
                        </label>
                        <div className="input-group">
                          <select
                            name="role"
                            className={`form-select ${
                              formik.touched.role && formik.errors.role
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="Role"
                            // value={formik.values.role}
                            // onChange={(e) =>
                            //   formik.setFieldValue(e.target.value)
                            // }
                            {...formik.getFieldProps('role')}
                            aria-label="Select Role">
                            <option value="">Select a role...</option>
                            <option value="super_admin">Super Admin</option>
                            <option value="admin">Admin</option>
                            {/* <!-- Add more options as needed --> */}
                          </select>
                        </div>
                        {formik.touched.role && formik.errors.role && (
                          <div className="text-danger">
                            {formik.errors.role}
                          </div>
                        )}
                      </div>
                    </div>

                    {/* <div className="row col-mb-3" /> */}
                    {/* <label>Change Password</label>
                    <hr className="mt-0" /> */}
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="newpass" className="form-label">
                          Password
                        </label>
                        <div className=" d-flex">
                          <input
                            name="newpass"
                            type={showNewPass ? 'text' : 'password'}
                            className={`form-control ${
                              formik.touched.newpass && formik.errors.newpass
                                ? 'is-invalid'
                                : ''
                            }`}
                            style={{
                              borderTopRightRadius: '0',
                              borderBottomRightRadius: '0',
                            }}
                            onBlur={formik.handleBlur}
                            id="newpass"
                            value={formik.values.newpass}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'newpass',
                                e.target.value?.trimStart(),
                              );
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            style={{
                              borderTopLeftRadius: '0', // Adjust the radius value
                              borderBottomLeftRadius: '0', // Adjust the radius value
                            }}
                            onClick={() => setShowNewPass(!showNewPass)}>
                            {showNewPass ? (
                              <i className="bi bi-eye-slash-fill"></i>
                            ) : (
                              <i className="bi bi-eye-fill"></i>
                            )}
                          </button>
                        </div>
                        {formik.touched.newpass && formik.errors.newpass && (
                          <div className="text-danger">
                            {formik.errors.newpass}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="newpass" className="form-label">
                          Repeat Password
                        </label>
                        <div className="d-flex">
                          <input
                            name="connewpass"
                            type={showConNewPass ? 'text' : 'password'}
                            className={`form-control ${
                              formik.touched.connewpass &&
                              formik.errors.connewpass
                                ? 'is-invalid'
                                : ''
                            }`}
                            style={{
                              borderTopRightRadius: '0',
                              borderBottomRightRadius: '0',
                            }}
                            onBlur={formik.handleBlur}
                            id="connewpass"
                            value={formik.values.connewpass}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'connewpass',
                                e.target.value?.trimStart(),
                              );
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            style={{
                              borderTopLeftRadius: '0', // Adjust the radius value
                              borderBottomLeftRadius: '0', // Adjust the radius value
                            }}
                            onClick={() => setShowConNewPass(!showConNewPass)}>
                            {showConNewPass ? (
                              <i className="bi bi-eye-slash-fill"></i>
                            ) : (
                              <i className="bi bi-eye-fill"></i>
                            )}
                          </button>
                        </div>
                        {formik.touched.connewpass &&
                          formik.errors.connewpass && (
                            <div className="text-danger">
                              {formik.errors.connewpass}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="text-center">
                      <button type="submit" className="btn btn-warning">
                        {!loading && (
                          <span className="indicator-label">Add User</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{display: 'block'}}>
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                  {/* End Profile Edit Form */}
                </div>
                <div className="tab-pane fade pt-3" id="profile-settings">
                  {/* Settings Form */}

                  {/* End settings Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AddUser;
