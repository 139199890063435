import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import constants, {getUser} from '../../utils/constants';

const Header = () => {
  const navigate = useNavigate();
  const user = JSON.parse(getUser());

  const logout = async () => {
    localStorage.removeItem(constants.USER);
    navigate('/login');
    window.location.reload();
  };

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <div className="d-flex align-items-center justify-content-between">
        <Link
          to="/"
          className="logo d-flex align-items-center text-decoration-none">
          {/* <img
            src={require('../../assets/images/logo.png')}
            alt="logo"
            style={{height: 50, width: 50}}
          /> */}
          <span className="d-none  d-lg-block ">Lynk Admin</span>
        </Link>
        <i
          className="bi bi-list toggle-sidebar-btn"
          onClick={() => document.body.classList.toggle('toggle-sidebar')}></i>
      </div>
      {/* <!-- End Logo --> */}

      {/* <!-- End Search Bar --> */}

      <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
          {/* <!-- End Search Icon--> */}

          {/* <!-- End Messages Nav --> */}

          <li className="nav-item dropdown pe-3">
            <a
              className="nav-link nav-profile d-flex align-items-center pe-0"
              href="#"
              data-bs-toggle="dropdown">
              <div className="border border-2 rounded-5  border-warning">
                <img
                  src={require('../../assets/images/user.png')}
                  // src={user?.image}
                  alt="Profile"
                  className="rounded-circle"
                />
              </div>
              <span className="d-none d-md-block dropdown-toggle ps-2">
                {/* {user?.firstName} {''} {user?.lastName} */}
                {user?.first_name} {''} {user?.last_name}
              </span>
            </a>
            {/* <!-- End Profile Iamge Icon --> */}

            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
              <li className="dropdown-header">
                <h6>
                  {user?.first_name} {''} {user?.last_name}
                </h6>
                <span>{user?.email}</span>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>

              <li>
                <Link
                  className="dropdown-item d-flex align-items-center"
                  to="/editprofile">
                  <i className="bi bi-person"></i>
                  <span>My Profile</span>
                </Link>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>

              <li>
                <Link
                  onClick={logout}
                  to={'/login'}
                  className="dropdown-item d-flex align-items-center">
                  <i className="bi bi-box-arrow-right"></i>
                  <span>Sign Out</span>
                </Link>
              </li>
            </ul>
            {/* <!-- End Profile Dropdown Items --> */}
          </li>
          {/* <!-- End Profile Nav --> */}
        </ul>
      </nav>
      {/* <!-- End Icons Navigation --> */}
    </header>
  );
};

export default Header;
