import React from 'react';
import {Link, useNavigate} from 'react-router-dom';

const ManageReports = () => {
  const navigate = useNavigate();

  return (
    <div id="main" className="main">
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Reports</li>
          </ol>
        </nav>
      </div>
      <div className="mt-4 mb-4">
        <h4>Manager Reports</h4>
      </div>
      <div className="d-flex   align-items-center">
        <span className="fs-5">Incomplete Driver Sign Ups</span>
        <div className="">
          <i
            role="button"
            onClick={() => navigate('/reports')}
            class="bi bi-pencil-square ms-4 fs-5 "></i>
        </div>
      </div>
      {/* <div className="card"></div> */}
    </div>
  );
};

export default ManageReports;
