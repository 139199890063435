import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {getUser} from '../../utils/constants';
import {fetchDataFromAPI} from '../../utils/api';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Processing from '../../components/Processing/Processing';

const UsersEdit = () => {
  const [loading, setLoading] = useState(false);

  const [showNewPass, setShowNewPass] = useState(false);
  const [showConNewPass, setShowConNewPass] = useState(false);

  const navigate = useNavigate();
  const {state} = useLocation();

  const user = JSON.parse(getUser());

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    setLoading(true);
    formik.setFieldValue('firstname', state?.user?.first_name);
    formik.setFieldValue('lastname', state?.user?.last_name);
    formik.setFieldValue('email', state?.user?.email);
    formik.setFieldValue('role', state?.user?.type);
    setLoading(false);
  };

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required('Please enter firstname'),
    lastname: Yup.string().required('Please enter lastname'),
    email: Yup.string()
      .email('Please enter valid email')
      .required('Please enter email'),
    role: Yup.string().notRequired(),
    newPass: Yup.string()
      .matches(
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[.@#$%^&+=!*])(?=\S+$).{8,}$/,
        'Password must be a minimum of 8 characters and should contain at least one uppercase and lowercase letter, a number, and a special character.',
      )
      .notRequired(),
    conNewPass: Yup.string()
      .oneOf(
        [Yup.ref('newPass'), null],
        'New password and repeat new password do not match.',
      )
      .notRequired(),
  });

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      role: '',
      newPass: '',
      conNewPass: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const {firstname, lastname, email, role, conNewPass, newPass} = values;

      if (newPass && !conNewPass) {
        formik.setFieldError('conNewPass', 'Please enter repeat new password');
        return;
      }

      const admin = JSON.parse(getUser());
      const formData = new FormData();

      formData.append('id', state?.user?.user_id);
      formData.append('first_name', firstname);
      formData.append('last_name', lastname);
      formData.append('email', email);
      formData.append('type', role);
      formData.append('password', conNewPass);
      setLoading(true);

      fetchDataFromAPI(
        'admin/profileUpdate',
        'post',
        formData,
        admin?.authToken,
      )
        .then((res) => {
          toast.success('User updated successfully ✅', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-success text-white',
          });
          setLoading(false);

          navigate('/users');
        })
        .catch((error) => {
          console.log(error?.response?.data?.message, error);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-danger text-white',
          });
          setLoading(false);
        });
    },
  });

  return (
    <main id="main" className="main">
      {loading && <Processing />}

      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/users">Users</Link>
            </li>
            <li className="breadcrumb-item active">
              {state?.user?.first_name} {''} {state?.user?.last_name}
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-1">
              {/* Bordered Tabs */}
              {/* <ul className="nav nav-tabs nav-tabs-bordered ">
                <label>Drive Name</label>
              </ul> */}
              <div className="tab-content pt-2">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row mb-3"></div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="firstname" className="form-label">
                          First Name
                        </label>
                        <input
                          name="firstname"
                          type="text"
                          className="form-control"
                          id="firstname"
                          value={formik.values.firstname}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'firstname',
                              e.target.value?.trimStart(),
                            );
                          }}
                        />
                        {formik.touched.firstname &&
                          formik.errors.firstname && (
                            <div className="text-danger">
                              {formik.errors.firstname}
                            </div>
                          )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="lastname" className="form-label">
                          Last Name
                        </label>
                        <input
                          name="lastname"
                          type="text"
                          className="form-control"
                          id="lastname"
                          value={formik.values.lastname}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'lastname',
                              e.target.value?.trimStart(),
                            );
                          }}
                        />
                        {formik.touched.lastname && formik.errors.lastname && (
                          <div className="text-danger">
                            {formik.errors.lastname}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <input
                          name="email"
                          type="text"
                          className="form-control"
                          id="email"
                          value={formik.values.email}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'email',
                              e.target.value?.trimStart(),
                            );
                          }}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="text-danger">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="Role" className="form-label">
                          Role
                        </label>
                        <div className="input-group">
                          <select
                            name="Role"
                            className="form-select"
                            id="Role"
                            // value={formik.values.role}
                            {...formik.getFieldProps('role')}
                            disabled={user?.email === state?.user?.email}
                            aria-label="Select Role">
                            {/* <option value="">Select a role...</option> */}
                            <option value="super_admin">Super Admin</option>
                            <option value="admin">Admin</option>
                            {/* <!-- Add more options as needed --> */}
                          </select>
                        </div>
                      </div>
                    </div>

                    {/* <div className="row col-mb-3" /> */}
                    <label>Change Password</label>
                    <hr className="mt-0" />
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="firstName" className="form-label">
                          New Password
                        </label>

                        <div className="d-flex">
                          <input
                            name="newPass"
                            type={showNewPass ? 'text' : 'password'}
                            className="form-control"
                            style={{
                              borderTopRightRadius: '0',
                              borderBottomRightRadius: '0',
                            }}
                            id="newPass"
                            onChange={(e) => {
                              formik.setFieldValue(
                                'newPass',
                                e.target.value?.trimStart(),
                              );
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            style={{
                              borderTopLeftRadius: '0', // Adjust the radius value
                              borderBottomLeftRadius: '0', // Adjust the radius value
                            }}
                            onClick={() => setShowNewPass(!showNewPass)}>
                            {showNewPass ? (
                              <i className="bi bi-eye-fill"></i>
                            ) : (
                              <i className="bi bi-eye-slash-fill"></i>
                            )}
                          </button>
                        </div>
                        {formik.touched.newPass && formik.errors.newPass && (
                          <div className="text-danger">
                            {formik.errors.newPass}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="lastName" className="form-label">
                          Repeat New Password
                        </label>
                        <div className="d-flex">
                          <input
                            name="conNewPass"
                            type={showConNewPass ? 'text' : 'password'}
                            className="form-control"
                            style={{
                              borderTopRightRadius: '0',
                              borderBottomRightRadius: '0',
                            }}
                            id="conNewPass"
                            onChange={(e) => {
                              formik.setFieldValue(
                                'conNewPass',
                                e.target.value?.trimStart(),
                              );
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            style={{
                              borderTopLeftRadius: '0', // Adjust the radius value
                              borderBottomLeftRadius: '0', // Adjust the radius value
                            }}
                            onClick={() => setShowConNewPass(!showConNewPass)}>
                            {showConNewPass ? (
                              <i className="bi bi-eye-fill"></i>
                            ) : (
                              <i className="bi bi-eye-slash-fill"></i>
                            )}
                          </button>
                        </div>
                        {formik.touched.conNewPass &&
                          formik.errors.conNewPass && (
                            <div className="text-danger">
                              {formik.errors.conNewPass}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="text-center">
                      <button type="submit" className="btn btn-warning ">
                        {!loading && (
                          <span className="indicator-label ">Save Updates</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{display: 'block'}}>
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                  {/* End Profile Edit Form */}
                </div>
                <div className="tab-pane fade pt-3" id="profile-settings">
                  {/* Settings Form */}

                  {/* End settings Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default UsersEdit;
